import { css } from 'styled-components';

const sizes = {
  xl: 1630,
  lg: 1200,
  md: 991,
  sm: 767,
  xs: 576
};

export const media = Object.keys(sizes).reduce((accumulator, label) => {
  const emSize = sizes[label] / 16;

  if (label === 'xl') {
    accumulator[label] = (...args) => css`
      @media (min-width: ${emSize}em) {
        ${css(...args)};
      }
    `;
  } else {
    accumulator[label] = (...args) => css`
      @media (max-width: ${emSize}em) {
        ${css(...args)};
      }
    `;
  }

  return accumulator;
}, {});

export const isDesktop = typeof window !== 'undefined' && window.innerWidth > sizes.md;
export const isBigDesktop = typeof window !== 'undefined' && window.innerWidth > sizes.xl;
export const isMobile = typeof window !== 'undefined' && window.innerWidth <= sizes.sm;
