import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { GlobalStyles } from 'themes'
import { BaseCSS } from 'styled-bootstrap-grid'

const Layout = ({ children }) => (
  <>
    <Helmet
      link={[
        { rel: 'stylesheet', href: 'https://use.typekit.net/rfv0xlt.css' },
        {
          rel: 'stylesheet',
          href:
            'https://fonts.googleapis.com/css?family=Playfair+Display:400,700',
        },
      ]}
      title="Robert W. Sadowski PLLC"
    />

    {children}

    <BaseCSS />
    <GlobalStyles />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
